import React, { useRef } from 'react'
import classnames from 'classnames/bind'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import styles from './Index.module.scss'

import Text from '@components/Text'
import layoutStyles from '@components/Layout/Layout.module.scss'
import PageTransition from '@components/PageTransition'
import SEO from '@components/seo'
import isiOS from '@utils/isiOS'

const cx = classnames.bind({ ...styles, ...layoutStyles })

const IndexPage = ({ location, showBorders }) => {
  const indexRef = useRef(null)

  return (
    <PageTransition location={location} pagePath="/">
      <div
        ref={indexRef}
        className={cx('index-page', 'about', {
          'page-borders': showBorders,
          'mobile-safari': isiOS(),
        })}
      >
        <SEO title="Snake Heist" />
        <div className={`section-container`}>
          <div className={`section`}>
            <div className={`row`}>
              <div className={`col`}>
                <div
                  className={cx('content-container')}
                  style={{
                    paddingTop: '8rem',
                    overflowY: 'scroll',
                    position: 'relative',
                    height: '100vh',
                  }}
                >
                  <div style={{ position: 'absolute' }}>
                    <Text tag="h1" type="h1">
                      Snakeheist: A Dollar Store Caper
                    </Text>
                    <br />
                    <Text tag="h2" type="h2">
                      "a wanabe zelot escapes granma's house & tries to figure out which voices to listen to"
                    </Text>
                    <br />

                    <p>
                      words by <strong>Harold Lehmann</strong>
                    </p>
                    <p>
                      music by <strong><a target="_blank" href="https://davydka.github.io/">David Whitely</a></strong>
                    </p>
                    <p>
                      {' '}
                      sound production by <strong>Brandt Gassman</strong>
                    </p>
                    <p>
                      karaoke animation and website by <strong><a target="_blank" href="https://davydka.github.io/">David Whitely</a></strong>
                    </p>
                    <p>
                      {' '}
                      puppet animation by <strong>Lake Simons</strong>
                    </p>

                    <br />
                    <p>
                      featuring voices:
                      <br />
                      &nbsp;✵&nbsp; Theresa Bucheister,
                      <br />
                      &nbsp;✵&nbsp; Kym Bernazky,
                      <br />
                      &nbsp;✵&nbsp; Lyana Fernandez,
                      <br />
                      &nbsp;✵&nbsp; Nick Jumara,
                      <br />
                      &nbsp;✵&nbsp; Harold Lehmann,
                      <br />
                      &nbsp;✵&nbsp; Richard Lehmann,
                      <br />
                      &nbsp;✵&nbsp; Sylvie Meltzer,
                      <br />
                      &nbsp;✵&nbsp; John Moore,
                      <br />
                      &nbsp;✵&nbsp; Brett Scieszka,
                      <br />
                      &nbsp;✵&nbsp; Kyle Triplett,
                      <br />
                      &nbsp;✵&nbsp; Tina West
                    </p>
                    <br />
                    <br />

                    <p>note from harold:</p>

                    <p>
                      this project is a part of an ongoing collaboration and inquiry into how processes of voice, text
                      and meaning may be evolving in our hyper digitized and mediated landscape. i began to write the
                      story of wanabe and his granma around february 2020. as a storyteller and mental health worker, i
                      am fascinated by the "voices" in our landscape and how we respond; "hearing voices" is a term
                      usually reserved for someone who is either ill or exalted; the truth is we are all constantly
                      hearing and responding to voices; what does this mean for our actions and relationships in this
                      time?
                    </p>

                    <p>
                      as i completed the text, the world was changed by covid-19 and new realities of socialization and
                      connection. David, Brandt and i worked to integrate the text with David's remarkable sound design
                      and Brandt's keen production. we were lucky to recruit 10 performers from across the globe, record
                      the chapters mostly by phone and begin a unique editing and design process.
                    </p>

                    <p>
                      as we discussed best way to present, David developed a format with intuitive use of a glitched
                      karaoke program and collage to animate and enhance the themes. we hope you enjoy and please share
                      your feedback! this is a conversation.
                    </p>

                    <br />
                    <br />

                    <p>
                      Contact:{' '}
                      <a style={{ textDecoration: 'underline' }} href="mailto:haroldlehmann@gmail.com">
                        haroldlehmann@gmail.com
                      </a>
                    </p>
                    <br />
                    <br />

                    <img src="/k/bed.png" alt="wannabe in bed" style={{ width: '100%' }} />

                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />

                    <Text tag="h4" type="h4">
                      Copyright © 2020
                      <br />
                      madmenofthemountain
                      <br />
                      Brooklyn, NY 11205
                      <br />
                      All rights reserved.
                    </Text>
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                  </div>
                  {/*  END of scroll container */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </PageTransition>
  )
}

IndexPage.propTypes = {
  t3d: PropTypes.object,
  kanvasRef: PropTypes.object,
  location: PropTypes.object,
  FTUI: PropTypes.bool,
  showBorders: PropTypes.bool,
  setHeroRef: PropTypes.func,
}

const mapStateToProps = ({ showBorders, FTUI }) => {
  return { showBorders, FTUI }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setHeroRef: (target) => dispatch({ type: `SETHEROREF`, payload: target }),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(IndexPage)
